<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">标签：</span>
        <el-input class="width_150" v-model="search_tag" placeholder="请输入标签" @keyup="search" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">刷新</el-button>
        <el-button type="primary" size="medium" @click="showDialog()">添加</el-button><i>最多50个标签,当前{{originData.length}}个</i>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="showDialog(scope.row)">编辑</span>
          <span class="operation" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加修改 -->
    <el-dialog :title="dialogTitle" v-model="dialog" width="492px" top="20vh" :close-on-click-modal="false">
      <div class="form_box">
        <div class="form_title">标签：</div>
        <el-input v-model="form.title" placeholder="标签名字" size="small"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialog = false" >取消</el-button>
          <el-button type="primary" @click="add" v-if="dialogTitle == '添加'">添加</el-button>
          <el-button type="primary" @click="edit" v-else>修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import httpClient from "@/config/httpClient";

export default {
  name: "tagList",
  components: {},
  data() {
    return {
      tableData: [], //数据
      originData: [], // 原始数据
      dialogTitle:"",
      dialog:false,
      form: {
        id:0,
        title:"",
      }, // 表单数据
      search_tag:"",
    };
  },
  computed: {},
  created() {
    this.getList()
  },
  mounted() {
  },
  methods: {
    search(){
      if (this.search_tag == ""){
        this.tableData = this.originData
      }else{
        var newList = [];
        for(var i in this.originData){
          if (this.originData[i].title.indexOf(this.search_tag) >= 0){
            newList.push(this.originData[i])
          }
        }
        this.tableData = newList
      }
    },
    getList(){
      httpClient("videoTagList").post().then((res)=>{
        console.log(res)
        if (res.code == 0){
          this.tableData = res.data.list
        }
      })
    },
    showDialog(row){
      if(row != undefined){
        this.dialogTitle = "编辑:"+row.title
        this.form.id = row.id
        this.form.title = row.title
      }else{
        this.dialogTitle = "添加"
        this.form.id = 0
        this.form.title = ""
      }
      this.dialog = true
    },
    add(){
      httpClient("videoTagAdd").post(this.form).then((res)=>{
        if (res.code == 0){
          this.dialog = false;
          this.getList()
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    edit(){
      httpClient("videoTagEdit").post(this.form).then((res)=>{
        if (res.code == 0){
          this.dialog = false;
          this.getList()
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    del(row){
      this.$confirm(
          "确定要删除标签【" + row.title + "】吗？",
          "提示",
          { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      ).then(()=>{
        httpClient("videoTagDel").post({id:row.id}).then((res)=>{
          if (res.code == 0){
            this.getList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      })
    }
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
</style>
